import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import EliteSmileyComponent from './components/EliteSmiley';
import PixiBrancheComponent from './components/PixiBranche';
import PostnummerfordelingComponent from './components/Postnummerfordeling'
import BranchefordelingComponent from './components/Branchefordeling'
import KontrollerPrUgeComponent from './components/KontrollerPrUge'
import KortKontrollerDeSidsteXDage from './components/KortKontrollerDeSidsteXDage'
import { Statistik } from './components/Statistik';

import './custom.css'
import KortKontrollerMedAnmaerkninger from './components/KortKontrollerMedAnmaerkninger';

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Layout>
                <Routes>
                    <Route exact path='/' element={<Home />} />
                    <Route path='/antal-kontroller-pr-uge' element={<KontrollerPrUgeComponent />} />
                    <Route path='/fordeling-af-branchetyper' element={<BranchefordelingComponent />} />
                    <Route path='/antal-virksomheder-pr-postnummer' element={<PostnummerfordelingComponent />} />
                    <Route path='/fordeling-af-elite-smileys' element={<EliteSmileyComponent />} />
                    <Route path='/fordeling-af-pixi-brancher' element={<PixiBrancheComponent />} />
                    <Route path='/kort-kontroller-de-sidste-dage' element={<KortKontrollerDeSidsteXDage />} />
                    <Route path='/kort-kontroller-med-anmaerkninger' element={<KortKontrollerMedAnmaerkninger />} />
                    <Route path='/statistik' element={<Statistik />} />
                </Routes>
            </Layout>
        );
    }
}
