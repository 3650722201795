import React, { Component } from 'react';
import { Nav, Container, Navbar, NavDropdown } from 'react-bootstrap';
import './NavMenu.css';
import Smiley from './img/g10b.png';

export class NavMenu extends Component {

    constructor(props) {
        super(props);

        this.toggleNavbarGrafer = this.toggleNavbarGrafer.bind(this);
        this.setDropdownOpenGrafer = this.setDropdownOpenGrafer.bind(this);
        this.toggleNavbarKort = this.toggleNavbarKort.bind(this);
        this.setDropdownOpenKort = this.setDropdownOpenKort.bind(this);

        this.state = {
            collapsedGrafer: true,
            collapsedKort: true,
            dropdownOpenGrafer: false,
            dropdownOpenKort: false
        };
    }

    toggleNavbarGrafer() {
        this.setState({
            collapsedGrafer: !this.state.collapsedGrafer
        });
    }

    setDropdownOpenGrafer() {
        this.setState({
            dropdownOpenGrafer: !this.state.dropdownOpenGrafer
        });
    }

    toggleNavbarKort() {
        this.setState({
            collapsedKort: !this.state.collapsedKort
        });
    }

    setDropdownOpenKort() {
        this.setState({
            dropdownOpenKort: !this.state.dropdownOpenKort
        });
    }



    render() {
        return (
            <header>
                <Navbar className="navbar navbar-expand-sm navbar-toggleable-sm">
                    <Container>
                        <Navbar.Brand href="/" className='text-dark'>
                            <img src={Smiley} height={30} width={30} style={{ marginRight: 8 }} alt="" />
                            <span id="logo-text">Smiley Data</span>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="justify-content-end" style={{ width: "100%" }}>
                                <Nav.Link className="text-dark" href="/">Forside</Nav.Link>
                                <Nav.Link className="text-dark" href="/statistik">Statistik</Nav.Link>
                                <NavDropdown title="Kort" id="basic-nav-dropdown" align="end">
                                    <NavDropdown.Header>Kontroller</NavDropdown.Header>
                                    <NavDropdown.Item href="/kort-kontroller-de-sidste-dage">Kontroller de sidste 14 dage</NavDropdown.Item>
                                    <NavDropdown.Item href="/kort-kontroller-med-anmaerkninger">Kontroller med anm&aelig;rkninger de sidste 30 dage</NavDropdown.Item>
                                </NavDropdown>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
                <div id="navspacer"></div>
            </header>
        );
    }
}
