import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import { getUrl } from '../utils/utils.js'
import GreenDot from './img/green-dot.png'
import RedDot from './img/red-dot.png'
import OrangeDot from './img/orange-dot.png'
import YellowDot from './img/yellow-dot.png'

export class KortKontrollerMedAnmaerkninger extends Component {

    constructor(props) {
        super(props);
        this.state = { componentdata: [], virksomhedsantal: [], virksomhedsantalmedgeodata: [] };
    }

    static defaultProps = {
        center: {
            lat: 56.1,
            lng: 11.5
        },
        zoom: 7.7
    };

    getContent(id) {
        var xmlHttp = new XMLHttpRequest();
        xmlHttp.open("GET", getUrl('/api/Virksomhed/') + id, false);
        xmlHttp.send(null);
        var t = JSON.parse(xmlHttp.responseText);

        return '<div>' +
            '<div class="row">' +
            '<div class="col-md-4">Navn</div>' +
            '<div class="col-md-8">' + t.navn1 + '</div>' +
            '</div>' +
            '<div class="row">' +
            '<div class="col-md-4">Adresse</div>' +
            '<div class="col-md-8">' + t.adresse1 + '<br>' + t.postnummer + ' ' + t._By + '</div>' +
            '</div>' +
            '<div class="row">' +
            '<div class="col-md-4">Officielt link</div>' +
            '<div class="col-md-8"><a href="' + t.url + '" target="_blank">' + t.url + '</a></div>' +
            '</div>' +
            '<div class="row">' +
            '<div class="col-md-4">Pixi Branche</div>' +
            '<div class="col-md-8">' + t.pixiBranche + '</div>' +
            '</div>' +
            '</div>';
    }

    async renderMarkers(map, maps) {
        await this.populateData();

        this.state.componentdata.forEach(c => {
            let tUrl = GreenDot
            if (c.senesteKontrol === '2') {
                tUrl = YellowDot
            } else if (c.senesteKontrol === '3') {
                tUrl = OrangeDot
            }
            else if (c.senesteKontrol === '4') {
                tUrl = RedDot
            }

            let j = new maps.Marker({
                position: { lat: parseFloat(c.geoLat), lng: parseFloat(c.geoLng) },
                map,
                title: c.navn,
                icon: {
                    url: tUrl
                }
            });
            j.addListener("click", () => {
                let fad = this.getContent(c.id);
                let infowindow = new maps.InfoWindow({
                    content: fad,
                    width: 300
                });
                infowindow.open(map, j);
            });
        }
        );
    }

    render() {
        var tommeObj = this.state.componentdata.filter(e => e.geoLat.length > 3 && e.geoLng.length > 3);

        return (<div>
            <div className="h4 text-center">
                Virksomheder som har haft kontrol med anmærkninger de sidste 30 dage
            </div>
            <div className="mb-2">
                Kortet viser kun de {typeof (tommeObj.length) == 'number' ? parseInt(tommeObj.length).toLocaleString() : ''} af {typeof (this.state.componentdata.length) == 'number' ? parseInt(this.state.componentdata.length).toLocaleString() : ''} virksomheder som har registreret deres geografiske placering.
            </div>
            <div style={{ height: '70vh', width: '100%' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
                    defaultCenter={this.props.center}
                    defaultZoom={this.props.zoom}
                    yesIWantToUseGoogleMapApiInternals={true}
                    onGoogleApiLoaded={({ map, maps }) => this.renderMarkers(map, maps)}
                >
                </GoogleMapReact>
            </div>
            <div>
                Kilde: <a href="https://www.findsmiley.dk/Sider/Downloads.aspx">findsmiley.dk</a>
            </div>
        </div>
        );
    }

    async populateData() {
        const response = await fetch(getUrl('/api/Virksomhed/geodata/kontrollerdagetilbagemedanmaerkning/30/'));
        const apidata = await response.json();
        this.setState({ componentdata: apidata });
    }
}

export default KortKontrollerMedAnmaerkninger;