import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import KontrollerPrHelligdagComponent from './KontrollerPrHelligdag';
import KontrollerPrUgeDag from './KontrollerPrUgedag';
import KortForside from './KortForside';



export class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>

                <KortForside />

            </div>
        );
    }
}
