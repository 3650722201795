import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import KontrollerPrHelligdagComponent from './KontrollerPrHelligdag';
import KontrollerPrUgeDag from './KontrollerPrUgedag';
import PostnummerfordelingComponent from './Postnummerfordeling';
import KontrollerPrUgeComponent from './KontrollerPrUge';



export class Statistik extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                <div className="h4 text-center">Statistik</div>


                <div className="row mt-4">
                    <div className="col-md-6 p-3">
                        <KontrollerPrHelligdagComponent />
                    </div>
                    <div className="col-md-6 p-3">
                        <KontrollerPrUgeDag />
                    </div>
                </div>
                <KontrollerPrUgeComponent />
                <PostnummerfordelingComponent />
            </div>
        );
    }
}
