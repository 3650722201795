import React from 'react';
import { Scatter } from 'react-chartjs-2';
import { getUrl } from '../utils/utils.js'

class PostnummerfordelingComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = { componentdata: [] };
    }

    componentDidMount() {
        this.populateData();
    }

    render() {
        let chart = {
            labels: this.state.componentdata.map(function (c) {
                return c["postnummer"];
            }),
            datasets: [
                {
                    label: "Postnummer Fordeling",
                    backgroundColor: "#3e95cd",
                    data: this.state.componentdata.map(function (c) {
                        return { x: c["postnummer"], y: c["antal"] };
                    })
                }
            ]
        };

        let chartoptions = {
            legend: {
                display: false
            },
            scales: {
                x: {
                    type: 'linear',
                    position: 'bottom',
                    scaleLabel: {
                        display: true,
                    },
                    title: {
                        display: true,
                        text: 'Postnummer'
                    },
                    ticks: {
                        min: 0,
                        max: 1000000,
                        callback: function (value, index, values) {
                            return "" + value;
                        }
                    }
                },
                y: {
                    scaleLabel: {
                        display: true,
                        labelString: 'Antal virksomheder'
                    },
                    title: {
                        display: true,
                        text: 'Antal virksomheder'
                    }
                }
            }
        }

        return <div>
            <div className="h3 text-center">
                Antal tilmeldte virksomheder pr. postnummer
            </div>
            <div>
                <Scatter data={chart} options={chartoptions} />
            </div>
            <div>
                Kilde: <a href="https://www.findsmiley.dk/Sider/Downloads.aspx">findsmiley.dk</a>
            </div>
        </div>;
    }

    async populateData() {
        const response = await fetch(getUrl('/api/Virksomhed/postnummerfordeling'));
        const apidata = await response.json();
        this.setState({ componentdata: apidata });
    }
}

export default PostnummerfordelingComponent;