import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import { getUrl } from '../utils/utils.js'
import { defineProps } from 'vue';
import { color } from 'chart.js/helpers';
import GreenDot from './img/green-dot.png'
import BlueDot from './img/blue-dot.png'
import RedDot from './img/red-dot.png'
import OrangeDot from './img/orange-dot.png'
import YellowDot from './img/yellow-dot.png'

export class KortForside extends Component {


    constructor(props) {
        super(props);

        let defaultProps = {
            center: {
                lat: 56.1,
                lng: 11.5
            },
            zoom: 7.7
        }

        let initcenter = {
            lat: defaultProps.center.lat,
            lng: defaultProps.center.lng
        };
        let initzoom = defaultProps.zoom;

        let showPosition = false;

        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                initcenter.lat = position.coords.latitude;
                initcenter.lng = position.coords.longitude;
                initzoom = 16;
                showPosition = true;
            }, (error) => {
                console.log(error.code, error.message);
            },
                { enableHighAccuracy: true, timeout: 5000, maximumAge: 10000 });
        }

        this.state = {
            componentdata: [], virksomhedsantal: [], virksomhedsantalmedgeodata: [], mapProps: {
                center: {
                    lat: initcenter.lat,
                    lng: initcenter.lng
                },
                zoom: initzoom
            },
            showMap: false,
            showPosition: showPosition
        };

        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.setState(x => x.mapProps.center.lat = position.coords.latitude);
                this.setState(x => x.mapProps.center.lng = position.coords.longitude);
                this.setState(x => x.mapProps.zoom = 16);
                this.setState(x => x.showPosition = true);
            });
        }


    }

    componentDidMount() {
        this.getVirksomhedsAntal();
        this.getVirksomhedsAntalMedGeoData();
    }

    getContent(id) {
        var xmlHttp = new XMLHttpRequest();
        xmlHttp.open("GET", getUrl('/api/Virksomhed/') + id, false);
        xmlHttp.send(null);
        var t = JSON.parse(xmlHttp.responseText);

        return '<div>' +
            '<div class="row">' +
            '<div class="col-md-4">Navn</div>' +
            '<div class="col-md-8">' + t.navn1 + '</div>' +
            '</div>' +
            '<div class="row">' +
            '<div class="col-md-4">Adresse</div>' +
            '<div class="col-md-8">' + t.adresse1 + '<br>' + t.postnummer + ' ' + t._By + '</div>' +
            '</div>' +
            '<div class="row">' +
            '<div class="col-md-4">Officielt link</div>' +
            '<div class="col-md-8"><a href="' + t.url + '" target="_blank">' + t.url + '</a></div>' +
            '</div>' +
            '<div class="row">' +
            '<div class="col-md-4">Pixi Branche</div>' +
            '<div class="col-md-8">' + t.pixiBranche + '</div>' +
            '</div>' +
            '</div>';
    }

    async renderMarkers(map, maps) {
        await this.populateData();

        const newCenter = new maps.LatLng(this.state.mapProps.center.lat, this.state.mapProps.center.lng);
        map.setCenter(newCenter);

        this.state.componentdata.forEach(c => {
            let tUrl = GreenDot
            if (c.senesteKontrol === '2') {
                tUrl = YellowDot
            } else if (c.senesteKontrol === '3') {
                tUrl = OrangeDot
            }
            else if (c.senesteKontrol === '4') {
                tUrl = RedDot
            }

            let j = new maps.Marker({
                position: { lat: parseFloat(c.geoLat), lng: parseFloat(c.geoLng) },
                map,
                title: c.navn,
                icon: {
                    url: tUrl
                }
            });
            j.addListener("click", () => {
                let fad = this.getContent(c.id);
                let infowindow = new maps.InfoWindow({
                    content: fad,
                    width: 300
                });
                infowindow.open(map, j);
            });
        }
        );

        if (this.state.showPosition === true) {
            let clientposition = new maps.Marker({
                position: { lat: parseFloat(this.state.mapProps.center.lat), lng: parseFloat(this.state.mapProps.center.lng) },
                map,
                title: 'Din position',
                icon: {
                    url: BlueDot
                }
            });
        }

        this.setState(x => x.showMap = true);
    }

    render() {

        return (<div style={this.state.showMap ? {} : { visibility: 'hidden' }}>
            <div className="h4 text-center">
                Se virksomheder i nærheden af dig
            </div>
            <div className='mt-4' style={{ height: '70vh', width: '100%' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
                    center={this.state.mapProps.center}
                    zoom={this.state.mapProps.zoom}
                    yesIWantToUseGoogleMapApiInternals={true}
                    onGoogleApiLoaded={({ map, maps }) => this.renderMarkers(map, maps)}
                >
                </GoogleMapReact>
            </div>
            <div>
                Kilde: <a href="https://www.findsmiley.dk/Sider/Downloads.aspx">findsmiley.dk</a>
            </div>
        </div>
        );
    }

    async populateData() {
        const response = await fetch(getUrl('/api/Virksomhed/geodata'));
        const apidata = await response.json();
        this.setState({ componentdata: apidata });
    }

    async getVirksomhedsAntal() {
        const response = await fetch(getUrl('/api/Virksomhed/antal/'));
        const apidata = await response.json();
        this.setState({ virksomhedsantal: apidata });
    }

    async getVirksomhedsAntalMedGeoData() {
        const response = await fetch(getUrl('/api/Virksomhed/antalmedgeodata/'));
        const apidata = await response.json();
        this.setState({ virksomhedsantalmedgeodata: apidata });
    }
}

export default KortForside;